import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translatePropertyType',
    standalone: false
})
export class TranslatePropertyTypePipe implements PipeTransform {
  private translations = {
    RESIDENTIAL_PROPERTY: { de: 'Wohnimmobilie', en: 'Residential Property' },
    COMMERCIAL_PROPERTY: { de: 'Gewerbeimmobilie', en: 'Commercial Property' },
    INDUSTRIAL_PROPERTY: { de: 'Industrieimmobilie', en: 'Industrial Property' },
    AGRICULTURAL_PROPERTY: { de: 'Landwirtschaftliche Immobilie', en: 'Agricultural Property' },
    OTHER: { de: 'Sonstiges', en: 'Other' },
  };

  transform(value: string, lang: 'de' | 'en'): string {
    return this.translations[value]?.[lang] || value;
  }
}
