<div class="onboarding-children-step-container">
  <div class="middle-content mb-3">
    <div class="row">
      <div class="col-12">
        <div class="block-title font-size-h4 fw-400 mb-20">Hast du Kinder?</div>
      </div>
    </div>

    <div class="row stepper">
      <div class="col-12">
        <div class="form-group row mb-0">
          <div class="mb-10 col-md-6 pr-2">
            <div class="selection-wrapper">
              <label for="childrenExist" class="selected-label">
                <input
                  type="radio"
                  name="childrenExist"
                  id="childrenExist"
                  (click)="setChildrenExist(true)"
                  [checked]="childrenExist"
                />
                <div class="selected-content" id="childrenExist-content">
                  <h4>Ja</h4>
                </div>
              </label>
            </div>
          </div>

          <div class="mb-10 col-md-6 pl-2">
            <div class="selection-wrapper">
              <label for="childrenNotExist" class="selected-label">
                <input
                  type="radio"
                  name="childrenNotExist"
                  id="childrenNotExist"
                  (click)="setChildrenExist(false)"
                  [checked]="!childrenExist"
                />
                <div class="selected-content" id="childrenNotExist-content">
                  <h4>Nein</h4>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          @if (childrenExist) {
            <div class="col-12 d-flex flex-column mb-3">
              <label class="theme-color text-16 mb-3">Wie viele Kinder hast du?</label>

              <div class="d-flex align-items-center gap-4">
                <button
                  class="btn purple-outline-btn"
                  [disabled]="childrenAmount() === 1"
                  (click)="setChildrenAmount('-')"
                >
                  <span class="text-20">-</span>
                </button>

                <div class="theme-color text-20">{{ childrenAmount() }}</div>

                <button class="btn purple-outline-btn" (click)="setChildrenAmount('+')">
                  <span class="text-20">+</span>
                </button>
              </div>
            </div>

            <div class="col-12">
              <label class="theme-color text-16" for="childrenAccumulatedAmount">
                Wie viel Kindergeld erhälst du?
              </label>

              <div class="input-group w-50">
                <input
                  type="text"
                  class="form-control form-control-lg w-25"
                  #childrenAccumulatedAmount
                  id="childrenAccumulatedAmount"
                  name="childrenAccumulatedAmount"
                  [(ngModel)]="personProfile.accountableChildrenAmount"
                  placeholder="0"
                  autocomplete="off"
                  (input)="onAccountableChildrenAmount($event)"
                />

                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="bi bi-currency-euro"></i>
                  </span>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6 text-left py-2">
      <button
        type="button"
        class="btn btn-rounded theme-secondary-color pointer-cursor"
        (click)="triggerPreviousStep.emit()"
      >
        <i class="bi bi-arrow-left mr-2"></i>
        <span>Zur&uuml;ck</span>
      </button>
    </div>

    <div class="col-6 text-right">
      <button type="button" class="btn btn-rounded btn-purple btn-modal" (click)="triggerNextStep.emit()">
        <span>Weiter</span>
        <i class="bi bi-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
</div>
