import { Component, Inject, isDevMode, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ConsentTypeEnum } from '../../shared/enum';

import { PersonUpdateService, UiChangeTriggersService } from '../../shared/global-store';
import { AgentModalComponent } from '../../shared/modals/agent-modal/agent-modal.component';

import { ClientInvitationModalComponent } from '../../shared/modals/client-invitation-modal/client-invitation-modal.component';
import { ConsentModalComponent } from '../../shared/modals/consent-modal/consent-modal.component';
import { OptionsWidgetModalComponent } from '../../shared/modals/options-widget-modal/options-widget-modal.component';
import { Advisor, Company, Person } from '../../shared/model';
import { AUTH_SERVICE_TOKEN, AuthService, ConsentService, UserSettingsService } from '../../shared/service';
import WithSafeArea from '../../shared/utils/WithSafeArea';

import WithSubscription from '../../shared/utils/WithSubscriptions';

@Component({
    selector: 'app-user-sidebar-modal',
    templateUrl: 'user-sidebar-modal.component.html',
    styleUrls: ['user-sidebar-modal.component.scss'],
    standalone: false
})
export class UserSidebarModalComponent extends WithSafeArea(WithSubscription(Object)) implements OnInit {
  protected isDev: boolean = false;

  protected company: Company = new Company();
  protected person: Person = new Person();
  protected advisor: Advisor;
  protected advisorLoading: boolean = true;
  protected consentType = ConsentTypeEnum;

  protected logoutSpinner: boolean = false;

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) protected readonly authService: AuthService,
    private readonly bsModalRef: BsModalRef,
    private readonly userSettingsService: UserSettingsService,
    private readonly modalService: BsModalService,
    private readonly consentService: ConsentService,
    private readonly personUpdateService: PersonUpdateService,
    private readonly router: Router,
    private readonly uiTriggersService: UiChangeTriggersService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (isDevMode()) {
      this.isDev = true;
    }

    this.updateUserSettings();
    this.listenToSubject(this.userSettingsService.profileDataChangedSubject, () => this.updateUserSettings());
  }

  public openThemeModal(): void {
    this.closeActiveMenu();
    const optionsWidgetModalRef = this.modalService.show(OptionsWidgetModalComponent, {});

    optionsWidgetModalRef.content.options = [
      { icon: 'sun', text: 'Light Mode', event: 'light' },
      { icon: 'moon', text: 'Dark Mode', event: 'dark' },
      { icon: 'layers', text: 'Automatisch', event: 'auto' },
      { icon: 'x-circle', text: 'Schließen', event: null },
    ];

    optionsWidgetModalRef.content.action.subscribe((value: any): void => {
      if (value) {
        this.uiTriggersService.themeSetting = value;
      } else {
        optionsWidgetModalRef.hide();
      }
    });
  }

  protected openAdvisorCard(): void {
    const advisorModalRef = this.modalService.show(AgentModalComponent, {});
    advisorModalRef.content.advisor = this.advisor;
    advisorModalRef.content.person = this.person;
    advisorModalRef.content.company = this.company;
    advisorModalRef.content.mandateApproval = this.person?.mandateApproval;

    advisorModalRef.content.closeAdvisorModal.subscribe((): void => {
      advisorModalRef.hide();
    });
    this.closeActiveMenu();
  }

  protected openClientInvitationModal(): void {
    const clientInvitationModalRef = this.modalService.show(ClientInvitationModalComponent, {});
    clientInvitationModalRef.content.advisorInfo = this.advisor;
    this.closeActiveMenu();
  }

  protected navigateToOtherPage(page: string): void {
    this.router.navigate([page]);
    this.closeActiveMenu();
  }

  protected goToOtherExternalUrl(url: string): void {
    window.open(url, '_blank');
    this.closeActiveMenu();
  }

  protected logout(): void {
    this.logoutSpinner = true;
    this.authService.logout();
  }

  protected closeActiveMenu(): void {
    this.bsModalRef.hide();
  }

  protected consentForCompany(con: ConsentTypeEnum): void {
    this.closeActiveMenu();
    this.consentService.consentByTypeAndCompany(ConsentTypeEnum[con], this.company.id).subscribe({
      next: (data): void => {
        const consentModalRef = this.modalService.show(ConsentModalComponent, {});
        consentModalRef.content.title = data.title;
        consentModalRef.content.description = data.html;

        consentModalRef.content.action.subscribe((value: boolean): void => {
          if (value === true) {
            consentModalRef.hide();
          }
        });
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected consent(con: ConsentTypeEnum): void {
    this.closeActiveMenu();
    this.consentService.consentByType(ConsentTypeEnum[con]).subscribe({
      next: (data): void => {
        const consentModalRef = this.modalService.show(ConsentModalComponent, {});
        consentModalRef.content.title = data.title;
        consentModalRef.content.description = data.html;

        consentModalRef.content.action.subscribe((value: boolean): void => {
          if (value === true) {
            consentModalRef.hide();
          }
        });
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private updateUserSettings(): void {
    this.userSettingsService.getUserProfile().subscribe({
      next: (data: Person): void => {
        this.person = data;
        this.advisor = data.advisor;
        this.company = data.advisor.company;
        this.advisorLoading = false;

        // avatar update from the settings subscription
        setTimeout((): void => {
          this.personUpdateService.getUpdatedPerson().subscribe((value: Person): void => {
            if (value && value?.profilePictureUrl) {
              this.person = value;
            }
          });
        }, 1000);
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected readonly window = window;
}
