import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

import { VinlivtUploadService } from '@vinlivt/upload';

import { environment } from '../../../environments/environment';

import { UiChangeTriggersService } from '../global-store';

import {
  BankAccount,
  BankAccountTransaction,
  BankAccountTransactionsRequest,
  BankAccountTransactionsResponse,
  BankCardManagerData,
  BankConnectionDto,
  DepotDashboardModel,
  DepotTransactionModel,
  DepotTransactionRequest,
  DepotTransactionResponse,
  DetectedContract,
  FilesToAttachModel,
  Thresholds,
  TransactionAttachmentModel,
  TransactionTag,
  UpdateAccountListModel,
  UpdateAccountNameAndThresholds,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class OpenBankingService {
  public constructor(
    private readonly http: HttpClient,
    private readonly uiChangeTriggersService: UiChangeTriggersService,
    private readonly vinlivtUploadService: VinlivtUploadService,
  ) {}

  public checkProviderAccess(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/open-banking/bank-connection/has-any`, {
      observe: 'response',
    });
  }

  public getWebFormToken(): Observable<string> {
    return this.http.post(
      `${environment.apiEndpoint}/open-banking/bank-connection/import?theme=${this.uiChangeTriggersService.themeSetting}`,
      null,
      { responseType: 'text' },
    );
  }

  public getBankAccountList(include_hidden: boolean = false): Observable<BankConnectionDto[]> {
    return this.http.get<BankConnectionDto[]>(`${environment.apiEndpoint}/open-banking/bank-connection`, {
      params: { include_hidden },
    });
  }

  public getBankCards(include_hidden: boolean = false): Observable<BankAccount[]> {
    return this.http.get<BankAccount[]>(`${environment.apiEndpoint}/open-banking/account`, {
      params: { include_hidden },
    });
  }

  public deleteBankAccount(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/open-banking/bank-connection/${id}`);
  }

  public getBankCardManagerData(): Observable<BankCardManagerData> {
    return this.http.get<BankCardManagerData>(`${environment.apiEndpoint}/open-banking/dashboard`);
  }

  public getDepotDashboard(): Observable<DepotDashboardModel> {
    return this.http.get<DepotDashboardModel>(`${environment?.apiEndpoint}/open-banking/dashboard/depot`);
  }

  public getDepotTransactions(depotTransactionsRequest: DepotTransactionRequest): Observable<DepotTransactionResponse> {
    let params: HttpParams = new HttpParams();

    if (depotTransactionsRequest) {
      Object.keys(depotTransactionsRequest).forEach((key: string): void => {
        const value = (depotTransactionsRequest as any)[key];
        if (value !== undefined && value !== null && value !== '') {
          if (Array.isArray(value)) {
            value.forEach((arrayValue): void => {
              params = params.append(key, arrayValue);
            });
          } else {
            params = params.set(key, value);
          }
        }
      });
    }

    return this.http.get<DepotTransactionResponse>(`${environment.apiEndpoint}/open-banking/depot-transaction`, {
      params,
    });
  }

  public getBankAccountTransactions(
    bankAccountTransactionsRequest?: BankAccountTransactionsRequest,
  ): Observable<BankAccountTransactionsResponse> {
    let params: HttpParams = new HttpParams();

    if (bankAccountTransactionsRequest) {
      Object.keys(bankAccountTransactionsRequest).forEach((key: string): void => {
        const value = (bankAccountTransactionsRequest as any)[key];
        if (value !== undefined && value !== null && value !== '') {
          if (Array.isArray(value)) {
            value.forEach((arrayValue): void => {
              params = params.append(key, arrayValue);
            });
          } else {
            params = params.set(key, value);
          }
        }
      });
    }

    return this.http.get<BankAccountTransactionsResponse>(`${environment.apiEndpoint}/open-banking/transaction`, {
      params,
    });
  }

  public transactionDetail(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2c/account/transaction/detail/${id}`);
  }

  public reportWrongTransaction(transaction: BankAccountTransaction): Observable<void> {
    return this.http.post<void>(environment.apiEndpoint + '/b2c/transaction/report/issue', transaction);
  }

  public getTransactionAttachment(transactionId: number): Observable<TransactionAttachmentModel[]> {
    return this.http.get<TransactionAttachmentModel[]>(
      `${environment.apiEndpoint}/open-banking/transaction/${transactionId}/attachment`,
    );
  }

  public postTransactionAttachment(files: FilesToAttachModel[], transactionId: number): Observable<string[]> {
    return this.http.post<string[]>(
      `${environment.apiEndpoint}/open-banking/transaction/${transactionId}/attachment`,
      files,
    );
  }

  public uploadAttachment(url: string, attachment: File): Observable<any> {
    return new Observable<any>((observer: Subscriber<any>) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', attachment.type);

      xhr.upload.addEventListener('progress', (event: ProgressEvent): void => {
        if (event.lengthComputable) {
          const progress: number = (event.loaded / event.total) * 100;
          observer.next({ progress: Math.round(progress) });
        }
      });

      xhr.onload = (): void => {
        if (xhr.status >= 200 && xhr.status < 300) {
          observer.next({ success: true });
          observer.complete();
        } else {
          observer.error(xhr.statusText);
        }
      };

      xhr.onerror = (): void => {
        observer.error('An error occurred while uploading the attachment.');
      };

      xhr.send(attachment);

      // Cleanup function
      return (): void => {
        xhr.abort();
      };
    });
  }

  public deleteTransactionAttachment(transactionId: number, attachmentId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/open-banking/transaction/${transactionId}/attachment/${attachmentId}`,
    );
  }

  public updateBankConnection(
    bankConnectionId: string | number,
    importNewAccounts: boolean = false,
    updateCredentials: boolean = false,
  ): Observable<string | null> {
    return this.http
      .post(
        `${environment.apiEndpoint}/open-banking/bank-connection/${bankConnectionId}/update?import_new_accounts=${importNewAccounts}&update_credentials=${updateCredentials}&theme=${this.uiChangeTriggersService.themeSetting}`,
        null,
        { responseType: 'text', observe: 'response' },
      )
      .pipe(map((response: HttpResponse<any>) => (response.status === 204 ? null : response.body)));
  }

  public getAccountThresholds(id: string): Observable<Thresholds> {
    return this.http.get<Thresholds>(`${environment.apiEndpoint}/open-banking/account/${id}/thresholds`);
  }

  public updateAccountAndThresholds(id: string, data: UpdateAccountNameAndThresholds): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/open-banking/account/${id}`, data);
  }

  public updateAccountList(body: UpdateAccountListModel[]): Observable<void> {
    return this.http.put<void>(`${environment.apiEndpoint}/open-banking/account/batch`, body);
  }

  public getDetectedContracts(): Observable<DetectedContract[]> {
    return this.http.get<DetectedContract[]>(`${environment.apiEndpoint}/open-banking/contract`);
  }

  public getTransactionTags(): Observable<TransactionTag[]> {
    return this.http.get<TransactionTag[]>(`${environment.apiEndpoint}/open-banking/tag`);
  }

  public postTransactionTag(tagName: string): Observable<TransactionTag> {
    return this.http.post<TransactionTag>(`${environment.apiEndpoint}/open-banking/tag`, tagName);
  }

  public editTransactionTag(tag: TransactionTag): Observable<TransactionTag> {
    return this.http.put<TransactionTag>(`${environment.apiEndpoint}/open-banking/tag/${tag.id}`, tag.name);
  }

  public deleteTransactionTag(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/open-banking/tag/${id}`);
  }

  public assignTagToTransaction(transactionId: number, tagId: number): Observable<void> {
    return this.http.post<void>(
      `${environment.apiEndpoint}/open-banking/transaction/${transactionId}/tag/${tagId}`,
      null,
    );
  }

  public dismissTagFromTransaction(transactionId: number, tagId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/open-banking/transaction/${transactionId}/tag/${tagId}`);
  }

  public ignoreDetectedContract(id: string): Observable<void> {
    return this.http.patch<void>(`${environment.apiEndpoint}/open-banking/contract/${id}?ignore=true`, null);
  }
}
