import { Component, EventEmitter, Input, OnInit, Output, signal, Signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PersonProfile } from '../../../model';

@Component({
  standalone: true,
  selector: 'app-onboarding-children-step',
  templateUrl: 'onboarding-children-step.component.html',
  styleUrls: ['onboarding-children-step.component.scss'],
  imports: [FormsModule],
})
export class OnboardingChildrenStepComponent implements OnInit {
  @Input() personProfile: PersonProfile;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected childrenExist: boolean = false;
  protected childrenAmount: Signal<number> = signal(1);
  protected childrenAccumulatedAmount: Signal<number> = signal(250);

  constructor() {}

  public ngOnInit(): void {
    if (this.personProfile.childrenAmount > 0 && typeof this.personProfile.childrenAmount !== 'undefined') {
      this.childrenExist = true;
      this.childrenAmount = signal(this.personProfile.childrenAmount);
      this.childrenAccumulatedAmount = signal(this.personProfile.accountableChildrenAmount);
    }
  }

  protected setChildrenExist(value: boolean): void {
    this.childrenExist = value;

    if (value) {
      this.childrenAmount = signal(1);
      this.childrenAccumulatedAmount = signal(250);
    } else {
      this.childrenAmount = signal(0);
      this.childrenAccumulatedAmount = signal(0);
    }

    this.personProfile.childrenAmount = this.childrenAmount();
    this.personProfile.accountableChildrenAmount = this.childrenAccumulatedAmount();
  }

  protected setChildrenAmount(value: string): void {
    if (value === '+') {
      this.childrenAmount = signal(this.childrenAmount() + 1);
      this.personProfile.childrenAmount = this.childrenAmount();
      this.setChildrenAccumulation();
    }

    if (value === '-' && this.childrenAmount() !== 1) {
      this.childrenAmount = signal(this.childrenAmount() - 1);
      this.personProfile.childrenAmount = this.childrenAmount();
      this.setChildrenAccumulation();
    }
  }

  protected setChildrenAccumulation(): void {
    this.childrenAccumulatedAmount = signal(this.childrenAmount() * 250);
    this.personProfile.accountableChildrenAmount = this.childrenAccumulatedAmount();
  }

  protected onAccountableChildrenAmount(event: any): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Allow only numbers and one decimal point
    value = value.replace(/[^0-9.]/g, ''); // Remove invalid characters
    const decimalParts = value.split('.');

    // Ensure only one decimal point is allowed
    if (decimalParts.length > 2) {
      value = `${decimalParts[0]}.${decimalParts.slice(1).join('')}`;
    }

    // Update the input value
    inputElement.value = value;

    // Avoid NaN in the model; assign null for invalid input
    this.childrenAccumulatedAmount = value !== '' && !isNaN(Number(value)) ? signal(Number(value)) : null;
    this.personProfile.accountableChildrenAmount = this.childrenAccumulatedAmount();
  }
}
