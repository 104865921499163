import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
    standalone: false
})
export class TruncatePipe implements PipeTransform {
  windowWidth: number;
  maxLength: number;

  constructor() {
    this.checkWindowSize();
  }

  checkWindowSize(): void {
    this.windowWidth = window.innerWidth;
  }

  transform(text: string, length: number, suffix: string = '...'): string {
    if (this.windowWidth < 499) {
      this.maxLength = 15;
    }

    // if (this.windowWidth < 400 && this.windowWidth > 370) {
    //   this.maxLength = 2;
    // }
    //
    // if (this.windowWidth < 370 && this.windowWidth > 350) {
    //   this.maxLength = 7;
    // }

    if (text.length > this.maxLength) {
      return suffix + text.substring(this.maxLength);
    }
    return text;
  }
}
