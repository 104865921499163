import {
  AfterViewChecked,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ExternalCalendarModalComponent } from '../external-calendar-modal/external-calendar-modal.component';
import { AdvisorContentViewComponent } from '../../components/advisor-content-view/advisor-content-view.component';
import { BrokerContractModalComponent } from '../broker-contract-modal/broker-contract-modal.component';
import { OptionsWidgetModalComponent } from '../options-widget-modal/options-widget-modal.component';

import { ModalsSafeAreaService } from '../../global-store';
import { BrokerMandateService } from '../../service';

import { Advisor, Company, MandateApproval, PartnerLinksModel, Person } from '../../model';

@Component({
  standalone: true,
  selector: 'app-agent-modal',
  templateUrl: './agent-modal.component.html',
  styleUrls: ['./agent-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, AdvisorContentViewComponent],
})
export class AgentModalComponent implements OnInit, AfterViewChecked {
  @Output() closeAdvisorModal = new EventEmitter();

  public safeAreaBottom: number;

  public isMobileView: boolean = false;
  public maxWidth: number = 499;
  public googleMapLink: any;

  public advisor: Advisor;
  public person: Person;
  public mandateApproval: MandateApproval = new MandateApproval();
  public company: Company = new Company();

  public meetingLink: string = '';
  public calendarLink: string = '';

  constructor(
    private modalService: BsModalService,
    private brokerMandateService: BrokerMandateService,
    private modalsSafeAreaService: ModalsSafeAreaService,
  ) {
    this.checkWindowSize();
    this.brokerMandate();
  }

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();

    setTimeout((): void => {
      this.advisor.partnerLinks.forEach((partnerLink: PartnerLinksModel): void => {
        if (partnerLink.partnerLinkType === 'RECOMMENDATION') {
          this.calendarLink = partnerLink.url;
        }

        if (partnerLink.partnerLinkType === 'MEETING') {
          this.meetingLink = partnerLink.url;
        }
      });
    }, 500);
  }

  public ngAfterViewChecked(): void {
    if (this.advisor?.street) {
      this.checkOperatingSystem();
    }
  }

  @HostListener('window:resize', ['$event'])
  protected onResize(event): void {
    this.checkWindowSize();
  }

  protected checkWindowSize(): void {
    const windowWidth = window.innerWidth;
    this.isMobileView = windowWidth <= this.maxWidth;
  }

  protected openGoogleMaps(): void {
    window.open(this.googleMapLink, '_blank');
  }

  protected openBrokerContractModal(): void {
    this.closeAdvisorModal.emit();

    setTimeout((): void => {
      const brokerContractModalRef = this.modalService.show(BrokerContractModalComponent, {
        ignoreBackdropClick: true,
      });

      brokerContractModalRef.content.brokerMandate = this.mandateApproval;

      brokerContractModalRef.content.finishBrokerContract.subscribe((value: boolean): void => {
        if (value) {
          brokerContractModalRef.hide();
          this.mandateApproval.status = 'PENDING';
        } else {
          brokerContractModalRef.hide();
        }
      });
    }, 500);
  }

  protected openCalenderFrame(): void {
    this.closeAdvisorModal.emit();

    setTimeout((): void => {
      const externalCalendarModalRef = this.modalService.show(ExternalCalendarModalComponent, {
        ignoreBackdropClick: true,
      });

      externalCalendarModalRef.content.iframeLink = this.calendarLink;
      externalCalendarModalRef.content.action.subscribe((done: boolean): void => {
        if (done) {
          externalCalendarModalRef.hide();
        }
      });
    }, 500);
  }

  protected openPhoneOptions(): void {
    const optionsWidgetModalRef = this.modalService.show(OptionsWidgetModalComponent, {});
    this.closeAdvisorModal.emit();

    optionsWidgetModalRef.content.options = [
      { icon: 'telephone', text: `${this.advisor.phoneNumber}`, event: 'callPhoneNumber' },
      { icon: 'phone', text: `${this.advisor.officePhoneNumber}`, event: 'callOfficeNumber' },
    ];

    optionsWidgetModalRef.content.action.subscribe((value: string): void => {
      if (value) {
        if (value === 'callPhoneNumber') {
          window.open(`tel:${this.advisor.phoneNumber}`);
        }

        if (value === 'callOfficeNumber') {
          window.open(`tel:${this.advisor.officePhoneNumber}`);
        }

        optionsWidgetModalRef.hide();
      } else {
        optionsWidgetModalRef.hide();
      }
    });
  }

  private checkOperatingSystem(): void {
    const device = navigator.platform;

    if (
      /* if we're on iOS, open in Apple Maps */
      device.indexOf('iPhone') !== -1 ||
      device.indexOf('iPad') !== -1 ||
      device.indexOf('iPod') !== -1
    ) {
      this.googleMapLink = `maps://maps.google.com/maps/place/${this.advisor?.street + this.advisor?.houseNumber},${
        this.advisor?.zipCode + this.advisor?.city
      }`;
      // TODO: use coords?+
    } else if (device.indexOf('Android') !== -1) {
      this.googleMapLink = `https://maps.google.com/maps/place/${this.advisor?.street + this.advisor?.houseNumber},${
        this.advisor?.zipCode + this.advisor?.city
      }`;
      // TODO: use coords?+
    } else {
      this.googleMapLink = `https://www.google.com/maps/place/${this.advisor?.street + this.advisor?.houseNumber},${
        this.advisor?.zipCode + this.advisor?.city
      }`;
    }
  }

  private brokerMandate(): void {
    this.brokerMandateService.init().subscribe({
      next: (data: any): void => {
        this.mandateApproval = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }
}
