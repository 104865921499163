<div class="options-widget-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="action.emit(null)">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body p-20">
    @for (option of options; track option) {
      <div
        class="option-block gray-border mb-10 d-flex align-items-center py-10 px-20 inside-block-bg pointer-cursor"
        [ngClass]="{ 'option-block-active': option.active }"
        (click)="action.emit(option.event)"
      >
        <i class="bi bi-{{ option.icon }} theme-color"></i>

        <div class="ml-30 font-size-h5 theme-color">{{ option.text }}</div>
      </div>
    }
  </div>
</div>
