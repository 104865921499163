import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumToArray',
    standalone: false
})
export class EnumConvertedToArrayPipe implements PipeTransform {
  transform(data: {}) {
    const keys = Object.keys(data);
    return keys.slice(keys.length / 2);
  }
}
