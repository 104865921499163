import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appTextareaAutosize]',
    standalone: false
})
export class TextareaAutosizeDirective {
  @Input() minRows = 1;
  @Input() maxRows = 3;

  private input: HTMLTextAreaElement;
  private offsetHeight: number = 0;
  private readonly avgLineHeight: number = 20;

  constructor(private element: ElementRef) {
    this.input = this.element.nativeElement;
  }

  @HostListener('input')
  onInput(): void {
    if (this.offsetHeight <= 0) {
      this.offsetHeight = this.input.scrollHeight;
    }

    this.input.rows = this.minRows;

    const rows = Math.floor((this.input.scrollHeight - this.offsetHeight) / this.avgLineHeight);

    const rowsCount = this.minRows + rows;

    this.input.rows = rowsCount > this.maxRows ? this.maxRows : rowsCount;
  }
}
