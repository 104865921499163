import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';

import { ModalsSafeAreaService } from '../../global-store';

@Component({
  standalone: true,
  selector: 'app-options-widget-modal',
  templateUrl: 'options-widget-modal.component.html',
  styleUrls: ['options-widget-modal.component.scss'],
  imports: [NgClass],
})
export class OptionsWidgetModalComponent implements OnInit {
  @Output() action = new EventEmitter<string>();

  public safeAreaBottom: number;
  public options: { icon: string; text: string; event: string; active?: boolean }[] = [];

  constructor(private modalsSafeAreaService: ModalsSafeAreaService) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();
  }
}
