import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';

import { PersonUpdateService, WelcomeMessageService } from '../../../global-store';
import { UserSettingsService } from '../../../service';

import { Person } from '../../../model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'app-onboarding-user-avatar-step',
  templateUrl: 'onboarding-user-avatar-step.component.html',
  styleUrls: ['onboarding-user-avatar-step.component.scss'],
  imports: [CommonModule, ImageCropperComponent],
})
export class OnboardingUserAvatarStepComponent {
  @Input() person: Person;

  @Output() triggerNextStep = new EventEmitter();
  @Output() triggerPreviousStep = new EventEmitter();

  protected selectedFile: File;
  protected requestLoading: boolean = false;

  protected imgChangeEvt: any = '';
  protected croppedImage: Blob | null = null;
  protected safeImageUrl: SafeUrl | null = 'https://static-assets.vinlivt.de/img/misc/bird.png';
  protected showCropper: boolean = false;
  protected canvasRotation: number = 0;
  protected rotation: number = 0;
  protected scale: number = 1;
  protected containWithinAspectRatio: boolean = false;
  protected transform: ImageTransform = {};

  // Error handling
  protected imageError: boolean = false;
  protected imageErrorText: string = '';

  constructor(
    private userSettingsService: UserSettingsService,
    private personUpdateService: PersonUpdateService,
    private welcomeMessageService: WelcomeMessageService,
    private sanitizer: DomSanitizer,
  ) {}

  protected onFileChange(event: any): void {
    if (
      event.target.files[0].type === 'image/jpeg' ||
      event.target.files[0].type === 'application/pdf' ||
      event.target.files[0].type === 'image/png'
    ) {
      this.imgChangeEvt = event;
      this.showCropper = true;
      this.imageError = false;
    } else {
      this.imageError = true;
      this.imageErrorText = 'Dieser Dateityp wird nicht unterstützt';
    }
  }

  protected imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.blob;
    this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.blob));
  }

  protected imageLoaded(): void {
    this.showCropper = true;
  }

  protected cropperReady(sourceImageDimensions: Dimensions): void {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  protected loadImageFailed(): void {
    // console.log('Load failed');
  }

  protected rotateLeft(): void {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  protected rotateRight(): void {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  protected flipHorizontal(): void {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  protected flipVertical(): void {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  protected resetImage(): void {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  protected zoomOut(): void {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  protected zoomIn(): void {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  protected toggleContainWithinAspectRatio(): void {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  protected clearProfilePic(): void {
    this.croppedImage = null;
    this.showCropper = false;
  }

  protected saveProfilePic(): void {
    this.selectedFile = new File([this.croppedImage], '46dDFR5223DFGDRGERG');
    this.showCropper = false;
  }

  private loadUserProfile(): void {
    this.userSettingsService.getUserProfile().subscribe({
      next: (data: Person): void => {
        this.person = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected saveUserAvatar(): void {
    this.requestLoading = true;

    if (this.selectedFile) {
      this.userSettingsService.upload(this.selectedFile).subscribe({
        complete: (): void => {
          this.imageError = false;
          this.loadUserProfile();

          this.requestLoading = false;
          this.triggerNextStep.emit();
          setTimeout((): void => {
            this.personUpdateService.setUpdatedPerson(this.person);
          }, 2000);
        },
        error: (error): void => {
          console.log(error);
          this.imageError = true;
          this.imageErrorText = 'Fehler beim hochladen. Bitte später nochmal versuchen';
        },
      });
    } else {
      this.welcomeMessageService.onBoardingState = true;
      this.requestLoading = false;
      this.triggerNextStep.emit();
    }
  }
}
