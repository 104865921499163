import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupBy',
    standalone: false
})
export class GroupByPipe implements PipeTransform {
  transform(collection: any[], property: string): any[] {
    if (!collection || !property) {
      return collection;
    }

    const groupedCollection = collection.reduce((accumulator, item) => {
      const key = item[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(item);
      return accumulator;
    }, {});

    return Object.keys(groupedCollection).map((key: string): { key: string; value: any } => ({
      key,
      value: groupedCollection[key],
    }));
  }
}
