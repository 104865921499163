import { Attachment } from './attachment.model';
import { Person } from './person';
import { Advisor, AdvisorChat } from './advisor';
import { PaymentIntervalTypeEnum, PeriodOfNoticeEnum } from '../enum';
import { InsuranceGroupTypeEnum } from '../enum/insurance-group-type.enum';
import { InsuranceTypeEnum } from '../enum/insurance-type.enum';

export class Contract {
  id: number;
  uid: string;
  insuranceGroup: InsuranceGroup = null;
  insuranceProvider: InsuranceProvider = null;
  title: string;
  created: Date;
  contractType: string;
  contractNumber: string;
  clientNumber: string;
  insuranceGroupName: string;
  insuranceProviderName: string;
  loanAmount: number = null;
  paymentIntervalType: PaymentIntervalTypeEnum;
  contractGroup: ContractGroup = null;
  finoContractId: string;
  amount: number;
  note: string;
  icon: string;
  sharable: boolean;
  category: Category;
  terminationDetails: TerminationDetails = new TerminationDetails();
  attachments: Array<Attachment> = new Array<Attachment>();
  unreadMessages: number;
  totalMessages: number;
  rating: number;
  advice: string;
  contractStartDate: Date;
  contractEndDate: Date;
  contractAdvice: ContractAdvice;
  archived: boolean;
  spriteCoordinate: string;
  activeConversation: boolean;
  contractPoolTransferState: string;
  poolIdentification: string;
  isCollapsed?: boolean;
}

export class ContractOverview {
  id: number;
  insuranceGroupName: string;
  insuranceProviderName: string;
  loanAmount: number = null;
  uid: string;
  title: string;
  contractType: string;
  active: boolean;
  amount: number;
  paymentIntervalType: PaymentIntervalTypeEnum;
  rating: number;
  contractGroupType: string;
  contractAdvice: ContractAdvice;
  selected?: boolean;
  icon: string;
  spriteCoordinate: string;
}

export class Category {
  categoryId: string;
  background: string;
  color: string;
  categoryName: string;
  parentCategoryName: string;
}

export interface IPill {
  title: string;
  active: boolean;
  uploadParam: string;
}

export interface IContractTypeCard {
  type: string;
  title: string;
  active: boolean;
}

export interface IContractPaymentInterval {
  type: string;
  title: string;
  active: boolean;
}

export interface ITips {
  tipTitle: string;
  tipDescription: string;
  tipIcon: string;
  tipIconText: string;
}

export class IContractBubble {
  percent: number;
  type: string;
  name: string;
  background: string;
}

export class InsuranceCategory {
  name: string;
  insuranceGroups: Array<InsuranceGroup>;
}

export class InsuranceProvider {
  id: number;
  name: string;
  checked?: boolean;
  displayName: string;
  spriteCoordinate: string;
}

export class InsuranceGroup {
  id: number;
  name: string;
  checked?: boolean;
  insuranceGroupType: InsuranceGroupTypeEnum;
  insuranceTypes: Array<InsuranceTypeEnum>;
}

export class IContractWidgetCard {
  percent: number;
  type: string;
  name: string;
}

export class ContractAdvice {
  id: number;
  uid: string;
  ratingType: string;
  advice: string;
  attachments: Array<Attachment>;
}

export class DashboardContractsStatistics {
  icon: string;
  name: string;
  total: number;
  percentage: number;
  insuranceGroupType: string;
}

export class ContractGroup {
  id: number;
  name: string;
  icon: string;
  color: string;
  contractGroupType: string;
}

export class ContractsRequest {
  includeDetected: boolean = true;
  contractGroup: ContractGroup = null;
  sortContractType: any = null;
}

export class ChatMessageResponse {
  offset: number;
  limit: number;
  totalElements: number;
  totalPages: number;
  messages: Array<ChatMessage> = new Array<ChatMessage>();
}

export class StatisticsResponse {
  unreadMessages: number;
}

export class TerminationDetails {
  id: string;
  created: Date;
  note: string;
  active: boolean = false;
  reminderDate: Date = new Date();
  periodOfNotice: PeriodOfNoticeEnum;
}

export class MyContractsResponse {
  insuranceContracts: ContractOverview[] = [];
  otherContracts: ContractOverview[] = [];
}

export class CommunicationContractResponse {
  myAdvisor: Advisor = new Advisor();
  contracts: Array<Contract> = new Array<Contract>();
  inactiveContracts: Array<Contract> = new Array<Contract>();
  activeContracts: Array<Contract> = new Array<Contract>();
  advisorChat: AdvisorChat = new AdvisorChat();
  unreadMessages: number;
  archived: boolean;
}

export class ChatMessage {
  id: string;
  message: string;
  client: Person;
  advisor: Advisor;
  userType: string;
  attachments: Array<Attachment>;
  contract: Contract;
  created: Date;
  dateLabel: string;
}

export class ChatMessageRequest {
  offset: number = 0;
  limit: number = 22;
  totalElements: number;
  totalPages: number;
  contractUid: string;
  advisorUid: string;
  query: string;
}

export interface VinhubMessageEvent {
  clientId: number;
  advisorId: number;
  contractId: number | null;
  chatMessageId: number;
}

export class DetectedContract {
  counterpartName: string;
  amount: number;
  interval: string;
  categoryName: string;
  isInsurance: boolean;
  logoUrl: string;
}

export class DetectedContractsResponseModel {
  insurance: Contract[];
  other: Contract[];
}
