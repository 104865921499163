export class NavigationTab {
  id: number;
  name: string;
  animatePercentage: number;
  icon?: string;
  amount?: number;
  redirectLink?: string; // for routing type
  includeLink?: string; // for routing type
  tabAction?: string; // for local type
}
